body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #e8e6f4;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 300;
}

.leads-table {
  padding: 0 0.75rem;
  font-size: 13px;
}

/* .bottom-widgets {
  padding: 0 .75rem;
} */

.h2 {
  font-size: 18px;
  font-weight: bold;
  text-align: left;
  border-bottom: #d2d2d2 solid;
  border-bottom-width: 1px;
  margin-bottom: 30px;
}

.sub-header {
  flex: 1;
  font-size: 16px;
  text-align: left;
  padding-top: 12px;
  padding-bottom: 12px;
  font-weight: bold;
}

.metric-number {
  font-size: 16px;
  padding-top: 12px;
  padding-bottom: 12px;
  font-weight: bold;
  margin-right: 12px;
}

.loading-controller {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 80px;
}

.box-shadow-card {
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);
}

.header-item {
  border-bottom-color: #000000;
  border-bottom-width: 10px;
  height: 40px;
  background-color: #f2f2f2;
  margin-bottom: 5px;
  padding-top: 5px;
  padding-left: 5px;
  border-radius: 5px;
}

.csv-selector-columns {
  padding-top: 50;
  padding-left: 40;
  flex: 1;
  flex-direction: 'row';
}

.lead-status {
  padding: 10px;
  border: 2px solid #fff;
  border-radius: 5px;
}

.lead-link {
  cursor: pointer;
}

.sla-integration img {
  max-width: 100%;
  padding: 0 20px;
}

.sla-action {
  overflow: auto;
}

.connect-messages,
.thank-youmessages {
  padding: 0 0 20px 0;
  border-bottom: 3px solid lightgrey;
}

[type='checkbox'] + span:not(.lever) {
  padding-left: 25px;
  margin-right: 20px;
}

.NLTDash .input-field {
  margin-top: 0;
  margin-bottom: 0;
  overflow-x: hidden;
}
