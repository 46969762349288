.loading {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 999;
  display: grid;
  place-items: center;
}

.loading p {
  font-size: 2rem;
  animation: breathing 1500ms ease-in-out infinite;
}

.loading > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@keyframes breathing {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}
